var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('DefaultModal',{ref:"modal",attrs:{"activator-name":"Dodaj","title":"Dodawanie do czarnej listy"},on:{"closedModal":_vm.clearForm,"formSubmitted":_vm.addToBlacklist}},[_c('AllProductsAutocomplete',{model:{value:(_vm.form.productId),callback:function ($$v) {_vm.$set(_vm.form, "productId", $$v)},expression:"form.productId"}}),_c('v-switch',{attrs:{"label":"Dodaj na stałe"},model:{value:(_vm.form.blackListedConstantly),callback:function ($$v) {_vm.$set(_vm.form, "blackListedConstantly", $$v)},expression:"form.blackListedConstantly"}}),(!_vm.form.blackListedConstantly)?_c('DatePickerInput',{attrs:{"label":"Ważność do","readonly":""},model:{value:(_vm.form.toDate),callback:function ($$v) {_vm.$set(_vm.form, "toDate", $$v)},expression:"form.toDate"}}):_vm._e()],1),_c('v-spacer'),_c('v-text-field',{staticClass:"shrink",attrs:{"append-icon":"mdi-magnify","label":"Wyszukaj","single-line":"","hide-details":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.fetchItems($event)},"blur":_vm.fetchItems},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.$data.$_paginationMixin_options,"item-key":"id","loading":_vm.$data.$_paginationMixin_fetchingItems,"search":_vm.search,"items-per-page":_vm.$data.$_paginationMixin_itemsPerPage,"page":1,"server-items-length":_vm.$data.$_paginationMixin_totalCount,"footer-props":{
      'items-per-page-options': _vm.$data.$_paginationMixin_rowsPerPageOptions,
    }},on:{"update:options":function($event){return _vm.$set(_vm.$data, "$_paginationMixin_options", $event)}},scopedSlots:_vm._u([{key:"item.product",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.product.name)+" ")]}},{key:"item.sku",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.product.sku)+" ")]}},{key:"item.toDate",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.toDate))+" ")]}},{key:"item.delete",fn:function(ref){
    var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.removeFromBL(item)}}},[_vm._v("mdi-delete")])]}}],null,true)}),_c('Confirm',{ref:"modalConfirm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }